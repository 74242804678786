// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ed0678;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #92003f;
  --ion-color-primary-tint: #ffc8d8;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// ion-content,ion-toolbar{
//   --background: #F9F9F9;
// }

// ion-header {
//   background: #F9F9F9;
// }

.inputItem {
  --border-radius: 12px;
  --border-color: #767676;
  --border-style: solid;
  --border-width: 1px;
  --highlight-color-focused: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-primary);
  ion-icon {
    color: var(--ion-color-primary);
  }
}

.customInput {
  border-radius: 12px;
  border-color: #767676;
  border-style: solid;
  border-width: 1px;

  input {
    padding-right: 10px !important;
  }
}

.inputButtonContainer {
  display: flex;
  flex-wrap: wrap;
}

.height-custom-input{
  height: 50px;
}

h2,
h1 {
  font-family: 'mukta-bold';
}
.small {
  font-size: small;
  color: #767676;
  margin-top: -15px;
}

ion-searchbar {
  .searchbar-input-container {
    --border-radius: 12px;
    border: 1px solid #767676;
    --box-shadow: 0px;
    --background: #f9f9f9;
    border-radius: 12px;
  }
}
.searchbar {
  width: 50%;
  margin: 0 auto;
  min-width: 350px;
}
.loading-hide {
  --background: none;
  background: transparent !important;
}
.loading-hide .loading-wrapper {
  box-shadow: none !important;
}
ion-spinner {
  width: 70px;
  height: 70px;
}
.imageModal {
  &::part(content) {
    background: rgba(0, 0, 0, 0.482);
    width: 100%;
    height: 100%;
  }
}
.deleteModal {
  &::part(content) {
    width: 600px;
    height: 170px;
    border-radius: 12px;
  }
}
.alertModal {
  &::part(content) {
    width: 400px;
    height: 250px;
    border-radius: 12px;
  }
}

.infoModal {
  &::part(content) {
    width: 400px;
    height: 350px;
    border-radius: 12px;
  }
}

.alertExtraModal {
  &::part(content) {
    width: 400px;
    height: 280px;
    border-radius: 12px;
  }
}
.editCityModal {
  &::part(content) {
    width: 600px;
    border-radius: 12px;
    height: auto;
  }
}
.userModal {
  &::part(content) {
    width: 600px;
    height: 95%;
    border-radius: 12px;
  }
}
.label {
  margin-top: 0px;
  text-align: left;
  margin-bottom: 0px;
}
.header {
  p {
    cursor: default;
    user-select: none;
  }
}
